import {
  add,
  compareAsc,
  differenceInMinutes,
  format,
  getHours,
  getMinutes,
  isAfter,
  isBefore,
  isSameMinute,
  isWithinInterval,
  max,
  min,
  sub,
} from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'

import busyIcon from 'features/Home/assets/busy.svg'
import cleanupIcon from 'features/Home/assets/cleanup.svg'
import disabledIcon from 'features/Home/assets/disabled.svg'
import freeIcon from 'features/Home/assets/free.svg'

import { DATE_FORMAT } from 'common/constants/dateFormatConstants'
import { TIME_FORMAT } from 'common/constants/timeFormat'
import { DateService } from 'common/services/dateService'

import type { ACTION_TYPES } from 'features/Home/constants/infoConstants'
import {
  APPOINTMENT_STATUS_ALLOWED_ACTIONS,
  COUNT_AS_OVERLAPPING,
  MIN_SCHEDULE_TIME_IN_MINUTES,
  PATIENT_STATUSES,
  ROOM_STATUS,
} from 'features/Home/constants/infoConstants'
import type { IPatientRoom, IPatientSlot } from 'features/Home/interfaces/IInfoPatient'
import type { IPatientSchedule } from 'features/Home/interfaces/IInfoSchedule'

const getIsContinueSlots = (slots: IPatientSlot[]): boolean => {
  for (let i = 0; i < slots.length; i++) {
    if (slots[i + 1] && slots[i + 1].id - slots[i].id !== 1) return false
  }
  return true
}

const getGroupedSlotsBySubsequence = (slots: IPatientSlot[]) =>
  slots.reduce((accumulator, current: IPatientSlot) => {
    if (
      !accumulator.length ||
      current.id !==
        accumulator[accumulator.length - 1][accumulator[accumulator.length - 1].length - 1].id + 1
    )
      accumulator.push([current])
    else accumulator[accumulator.length - 1].push(current)

    return accumulator
  }, [])

const getSubsequenceByHour = (time: number, slots: IPatientSlot[]) =>
  getGroupedSlotsBySubsequence(slots).find((slots: IPatientSlot[]) =>
    slots.some((slot: IPatientSlot): boolean => {
      const startTime = Number(slot.start_time.split(':').at(0))
      const endTime = Number(slot.end_time.split(':').at(0))
      for (let i = startTime; i <= endTime; i++) {
        if (i === time) return true
      }
      return false
    }),
  )

const generateDisabledTimes = (options: any[], date: string, selectedStart?: Date) => {
  const disabledHours: number[] = []
  const disabledMinutes: Record<number, number[]> = {}
  const selectedStartHour = selectedStart ? getHours(selectedStart) : 0
  const selectedStartMinute = selectedStart ? getMinutes(selectedStart) : 0

  const updateDisabledMinutes = (hour: number, startMinute: number, endMinute: number) => {
    if (disabledMinutes[hour]) {
      disabledMinutes[hour].push(
        ...Array.from({ length: endMinute - startMinute + 1 }, (_, i) => i + startMinute),
      )
    } else {
      disabledMinutes[hour] = Array.from(
        { length: endMinute - startMinute + 1 },
        (_, i) => i + startMinute,
      )
    }
  }

  if (selectedStartHour > 0) {
    Array.from({ length: selectedStartHour + 1 }, (_, i) => i).forEach((hour) => {
      if (hour === selectedStartHour) {
        const updatedHour = add(
          new Date(
            `${date} ${DateService.getHourFromNumbers({
              hour: selectedStartHour,
              minute: selectedStartMinute,
            })}`,
          ),
          {
            minutes: MIN_SCHEDULE_TIME_IN_MINUTES - 1,
          },
        )

        const updatedMinutes = getMinutes(updatedHour)
        const updatedHours = getHours(updatedHour)

        updateDisabledMinutes(updatedHours, 0, updatedMinutes)

        if (updatedHours > hour) {
          updateDisabledMinutes(hour, 0, 59)
        }
      } else {
        updateDisabledMinutes(hour, 0, 59)
      }
    })
  }

  const sortedOptions = getSortedSlots(options, date)
  const firstOption = sortedOptions.find(
    (option: any) =>
      isAfter(option.start_time, selectedStart) || isSameMinute(option.start_time, selectedStart),
  )
  if (firstOption && selectedStartHour > 0) {
    const firstOptionHour: any = getHours(firstOption.start_time)
    const firstOptionMinute: any = getMinutes(firstOption.start_time)

    Array.from({ length: 24 - firstOptionHour }, (_, i) => i + firstOptionHour).forEach((hour) => {
      if (hour === firstOptionHour) {
        updateDisabledMinutes(hour, firstOptionMinute + 1, 59)
      } else {
        updateDisabledMinutes(hour, 0, 59)
      }
    })
  } else {
    options.forEach((item, index) => {
      let endDate = new Date(`${date} ${item.end_time}`)
      const startHour = getHours(new Date(`${date} ${item.start_time}`))
      if (index === options.length - 1) {
        endDate = sub(endDate, { minutes: 1 })
      }
      let endHour = getHours(endDate)
      const startMinute = getMinutes(new Date(`${date} ${item.start_time}`))
      const endMinute = getMinutes(endDate)

      if (startHour === endHour) {
        updateDisabledMinutes(startHour, startMinute + 1, endMinute)
      }

      if (startHour < endHour) {
        const hoursBetween = Array.from(
          { length: endHour - startHour + 1 },
          (_, i) => i + startHour,
        )

        hoursBetween.forEach((hour) => {
          if (hour === 0) {
            updateDisabledMinutes(hour, 0, 59)
          }
          if (hour === startHour && hour !== 0) {
            updateDisabledMinutes(hour, startMinute, 59)
          }

          if (hour === endHour && hour !== 23 && endMinute > 0) {
            updateDisabledMinutes(hour, 0, endMinute)
          }

          if (hour === 23) {
            updateDisabledMinutes(hour, 0, 59)
          }

          if (hour !== startHour && hour !== endHour) {
            updateDisabledMinutes(hour, 0, 59)
          }
        })
      }
    })
  }

  Array.from(Object.keys(disabledMinutes)).forEach((key: any) => {
    const uniqueMinutes = Array.from(new Set(disabledMinutes[key]))
    disabledMinutes[key] = uniqueMinutes
    if (uniqueMinutes.length > 59) disabledHours.push(Number(key))
  })

  return { hours: disabledHours, minutes: disabledMinutes }
}
const getTopPositionByCurrentTime = (interval: number) => {
  const currentHour = new Date()
  const startTime = DateService.getDateByTime('08:00')

  return {
    top: differenceInMinutes(currentHour, startTime) * (57 / interval) + 22, // Height per minute + default height column
    data: format(currentHour, TIME_FORMAT.TIME_PICKER_HH_SS),
  }
}

const getScheduleColor = (status: any) => {
  switch (status) {
    case PATIENT_STATUSES.UNCONFIRMED:
      return {
        color: '#967A03',
        backgroundColor: '#FCF3CC',
        borderRadius: '12px',
      }
    case PATIENT_STATUSES.CONFIRMED:
      return {
        color: '#477648',
        backgroundColor: '#E4F2E4',
        borderRadius: '12px',
      }

    case PATIENT_STATUSES.CHECKED_OUT:
      return {
        color: '#477648',
        backgroundColor: '#E4F2E4',
        borderRadius: '12px',
      }
    case PATIENT_STATUSES.REFUSED:
      return { color: '#E32626', backgroundColor: '#FDF1F1', borderRadius: '12px' }

    case PATIENT_STATUSES.RESCHEDULE:
      return { color: '#E32626', backgroundColor: '#FDF1F1', borderRadius: '12px' }

    case PATIENT_STATUSES.NO_SHOW:
      return { color: '#E32626', backgroundColor: '#FDF1F1', borderRadius: '12px' }

    case PATIENT_STATUSES.CHECKED_IN_ON_SITE:
      return {
        color: '#562ACB',
        backgroundColor: '#F1ECFE',
        borderRadius: '12px',
      }

    case PATIENT_STATUSES.WALK_IN:
      return { color: '#562ACB', backgroundColor: '#F1ECFE', borderRadius: '12px' }

    case PATIENT_STATUSES.CHECK_IN_REJECTED:
      return {
        color: 'white',
        backgroundColor: '#FF8F8F',
        borderRadius: '12px',
      }

    case PATIENT_STATUSES.CANCELED:
      return { color: '#070707', backgroundColor: '#E7E7E7', borderRadius: '12px' }

    case PATIENT_STATUSES.DELETED:
      return { color: '#070707', backgroundColor: '#E7E7E7', borderRadius: '12px' }

    case PATIENT_STATUSES.DISABLED:
      return {
        color: '#F2F2F7',
        backgroundColor: '#F2F2F7',
        borderRadius: 0,
        width: 'calc(100% + 8px)',
      }
    default:
      return {}
  }
}

const getRoomStatusIcon = (status: ROOM_STATUS) => {
  switch (status) {
    case ROOM_STATUS.BUSY:
      return busyIcon

    case ROOM_STATUS.CLEANUP:
      return cleanupIcon

    case ROOM_STATUS.FREE:
      return freeIcon

    case ROOM_STATUS.DISABLE:
      return disabledIcon

    default:
      return null
  }
}

const getSortedSlots = (slots: IPatientSlot[], date?: string) => {
  const sortedSlots = slots
    .map((slot) => ({
      start_time: date
        ? new Date(`${date} ${slot.start_time}`)
        : DateService.getDateByTime(slot.start_time),
      id: slot.id,
      end_time: date
        ? new Date(`${date} ${slot.end_time}`)
        : DateService.getDateByTime(slot.end_time),
    }))
    .sort((a, b) => compareAsc(a.start_time, b.start_time))

  return sortedSlots
}

const getSortedPatientsByEndDate = (patients: IPatientSchedule[]) => {
  const sortedPatients = patients
    .map((patient) => ({
      ...patient,
      start_time: DateService.getDateByTime(patient.start_time),
      end_time: DateService.getDateByTime(patient.end_time),
    }))
    .sort((a, b) => compareAsc(a.start_time, b.start_time))

  return sortedPatients.map((patient) => ({
    ...patient,
    end_time: format(patient.end_time, TIME_FORMAT.TIME_PICKER_HH_SS),
    start_time: format(patient.start_time, TIME_FORMAT.TIME_PICKER_HH_SS),
  }))
}
const isSameOrBefore = (date1: Date, date2: Date): boolean => {
  return isSameMinute(date1, date2) || isBefore(date1, date2)
}

const isSameOrAfter = (date1: Date, date2: Date): boolean => {
  return isSameMinute(date1, date2) || isAfter(date1, date2)
}

const checkIsOverlappingIntervals = (testInterval: any, start: Date, end: Date, room: string) => {
  // Round only start and end dates to nearest minute
  const roundedStart = start instanceof Date ? new Date(start.setSeconds(0, 0)) : start
  const roundedEnd = end instanceof Date ? new Date(end.setSeconds(0, 0)) : end
  return (
    (room === testInterval.resourceId || room === testInterval.room?.label) &&
    (((isSameMinute(roundedStart, testInterval.start) ||
      isAfter(roundedStart, testInterval.start)) &&
      isBefore(roundedStart, testInterval.end) &&
      isAfter(roundedEnd, testInterval.start)) ||
      (isBefore(roundedStart, testInterval.start) && isAfter(roundedEnd, testInterval.start)))
  )
}

const getNumberOfOverlappingIntervals = (testInterval: any, intervals: any, room: string) => {
  const overlappingEvents: any = []

  intervals.forEach((item: any) => {
    if (checkIsOverlappingIntervals(item, testInterval.start, testInterval.end, room)) {
      let foundOverlap = false

      overlappingEvents.forEach((eventsArray: any) => {
        if (
          eventsArray.every((event: any) =>
            checkIsOverlappingIntervals(event, item.start, item.end, room),
          )
        ) {
          eventsArray.push(item)
          foundOverlap = true
        }
      })

      if (!foundOverlap) {
        overlappingEvents.push([item])
      }
    }
  })

  const nrOfOverlappingEvents =
    overlappingEvents.length > 0
      ? Math.max(...overlappingEvents.map((item: any) => item.length)) + 1
      : 1

  return {
    nrOfOverlappingEvents,
    overlappingEvents,
  }
}

const getDisabledSlotsBasedOnSortedSlots = (sortedSlots: any[]) => {
  if (sortedSlots.length === 0) {
    return []
  }
  const groupOfSlots = []
  let currentGroup = [sortedSlots[0]]

  for (let i = 1; i < sortedSlots.length; i++) {
    if (sortedSlots[i].id === sortedSlots[i - 1].id + 1) {
      currentGroup.push(sortedSlots[i])
    } else {
      groupOfSlots.push(currentGroup)
      currentGroup = [sortedSlots[i]]
    }
  }

  groupOfSlots.push(currentGroup)

  const updatedSlots = groupOfSlots.map((group) => {
    const start_time = group[0]?.start_time
    const end_time = group[group.length - 1]?.end_time

    return {
      id: group[0]?.id,
      start_time,
      end_time,
    }
  })

  return updatedSlots
}

const getTimePickerOptions = (
  schedules: any,
  date: string,
  timeZone: string,
  selectedRoom?: IPatientRoom,
) => {
  if (!selectedRoom) {
    return []
  }
  const lastReservedSchedules: IPatientSchedule[] | undefined = getSortedPatientsByEndDate(
    schedules,
  ).filter(
    (item) =>
      item?.room?.value === selectedRoom?.value &&
      COUNT_AS_OVERLAPPING.includes(item?.status?.code),
  )

  const availableSortedSlots = getSortedSlots(selectedRoom.slots).map((slot) => ({
    ...slot,
    start_time: format(slot.start_time, TIME_FORMAT.TIME_PICKER_HH_SS),
    end_time: format(slot.end_time, TIME_FORMAT.TIME_PICKER_HH_SS),
  }))

  const updatedSlots = getDisabledSlotsBasedOnSortedSlots(availableSortedSlots)

  const disabledIntervals: any[] = []
  updatedSlots.forEach((item, index) => {
    if (index === 0) {
      const currentDayHour = getHours(utcToZonedTime(new Date(), timeZone))
      const currentDayMinute = getMinutes(utcToZonedTime(new Date(), timeZone))
      disabledIntervals.push({
        start_time: '00:00',
        end_time:
          DateService.getIsValidAndCurrentDay(date, timeZone) &&
          isAfter(utcToZonedTime(new Date(), timeZone), new Date(`${date} ${item.start_time}`))
            ? `${DateService.getHourFromNumbers({
                hour: currentDayHour,
                minute: currentDayMinute,
              })}`
            : item.start_time,
      })
    }

    if (index === updatedSlots.length - 1) {
      disabledIntervals.push({
        start_time: item.end_time,
        end_time: '23:59',
      })
    }

    if (index < updatedSlots.length - 1 && item.end_time !== updatedSlots[index + 1].start_time) {
      disabledIntervals.push({
        start_time: item.end_time,
        end_time: updatedSlots[index + 1].start_time,
      })
    }
  })

  const lastPropTableSchedules = lastReservedSchedules.map((lastReservedSchedule) => ({
    start_time: lastReservedSchedule.start_time,
    end_time: lastReservedSchedule.end_time,
  }))

  const processedIntervals = lastPropTableSchedules.map((interval) => ({
    start: DateService.getDateByTime(interval.start_time),
    end: DateService.getDateByTime(interval.end_time),
  }))

  let overlappingEventsList: any[][] = [[]]

  const processOverlappingEvents = (testInterval: any) => {
    const overlappingWithCurrent: any[] = []
    processedIntervals.forEach((interval) => {
      const areOverlapping =
        checkIsOverlappingIntervals(
          { ...testInterval, resourceId: 'check' },
          interval.start,
          interval.end,
          'check',
        ) &&
        (overlappingWithCurrent.length === 0 ||
          overlappingWithCurrent.every((element) =>
            checkIsOverlappingIntervals(
              { ...interval, resourceId: 'check' },
              element.start,
              element.end,
              'check',
            ),
          ))

      if (areOverlapping) {
        overlappingWithCurrent.push(interval)
      }
    })
    overlappingEventsList.push(overlappingWithCurrent)
  }

  processedIntervals.forEach((interval) => processOverlappingEvents(interval))

  overlappingEventsList = overlappingEventsList.filter(
    (eventsList) => eventsList.length >= selectedRoom.max_patients,
  )

  let maxTimeIntervals: any[] = overlappingEventsList.map((eventList) => {
    const startTimeArrays = eventList.map((event) => event.start)
    const endTimeArrays = eventList.map((event) => event.end)

    const startTime = max(startTimeArrays)
    const endTime = min(endTimeArrays)

    return {
      start: startTime,
      end: endTime,
    }
  })

  maxTimeIntervals = maxTimeIntervals.map((interval) => {
    const startHour = getHours(interval.start)
    const startMinute = getMinutes(interval.start)
    const endHour = getHours(interval.end)
    const endMinute = getMinutes(interval.end)

    return {
      start_time: `${startHour < 10 ? `0${startHour}` : startHour}:${
        startMinute < 10 ? `0${startMinute}` : startMinute
      }`,
      end_time: `${endHour < 10 ? `0${endHour}` : endHour}:${
        endMinute < 10 ? `0${endMinute}` : endMinute
      }`,
    }
  })

  return [...disabledIntervals, ...maxTimeIntervals]
}

const checkAreAllHoursBetweenAvailable = (
  startHour: number,
  endHour: number,
  disabledHours: number[],
) => {
  const areAllAvailable = Array.from(
    { length: endHour - startHour + 1 },
    (_, i) => i + startHour,
  ).every((hour) => !disabledHours.includes(hour))

  return areAllAvailable
}

const checkAreAllMinutesBetweenAvailable = (
  startHour: number,
  endHour: number,
  startMinute: number,
  endMinute: number,
  disabledTime: any,
) => {
  let areAllAvailable = true

  if (endHour === startHour) {
    areAllAvailable = (disabledTime.minutes?.[startHour] || []).every(
      (minute: number) => minute < startMinute || minute > endMinute,
    )

    return areAllAvailable
  }

  if (endHour === startHour + 1) {
    areAllAvailable = (disabledTime.minutes?.[startHour] || []).every(
      (minute: number) => minute < startMinute,
    )
    areAllAvailable =
      areAllAvailable &&
      (disabledTime.minutes?.[endHour] || []).every((minute: number) => minute > endMinute)

    return areAllAvailable
  }

  Array.from({ length: endHour - startHour + 1 }, (_, i) => i + startHour).forEach((hour) => {
    if (!areAllAvailable) {
      return
    }

    if (hour === startHour) {
      areAllAvailable = (disabledTime.minutes?.[hour] || []).every(
        (minute: number) => minute < startMinute,
      )
    }

    if (hour === endHour) {
      areAllAvailable = (disabledTime.minutes?.[hour] || []).every(
        (minute: number) => minute > endMinute,
      )
    }

    if (hour > startHour && hour < endHour) {
      areAllAvailable = (disabledTime.minutes?.[hour] || []).length === 0
    }
  })

  return areAllAvailable
}

const checkIfActionShouldBeVisible = (action: ACTION_TYPES, status: PATIENT_STATUSES) => {
  if (!status) {
    return true
  }
  return APPOINTMENT_STATUS_ALLOWED_ACTIONS[status].includes(action)
}

const checkIfCurrentDateOverlapDisabledIntervals = (
  disabledIntervals: any[],
  room: IPatientRoom,
  dayInterval: { min: number; max: number },
  timeZone: string,
  bufferTime?: any,
) => {
  const currentDateToTimeZone = utcToZonedTime(new Date(), timeZone)
  const dateString = format(currentDateToTimeZone, DATE_FORMAT.CALENDAR_DATE)

  const startDate = new Date(`${dateString} ${dayInterval.min}`)
  const adjustedStartDate = sub(startDate, { minutes: bufferTime?.before })

  const endDate = new Date(`${dateString} ${dayInterval.max}`)
  const adjustedEndDate = add(endDate, { minutes: bufferTime?.after })

  if (
    isBefore(currentDateToTimeZone, adjustedStartDate) ||
    isAfter(currentDateToTimeZone, adjustedEndDate)
  ) {
    return true
  }

  const disabledIntervalsForRoom = disabledIntervals.filter(
    (item: any) => item.resourceId === `${room?.label}/${room?.value}`,
  )

  const shouldDisableDropdown = disabledIntervalsForRoom.some((item: any) =>
    isWithinInterval(currentDateToTimeZone, { start: item.start, end: item.end }),
  )

  return shouldDisableDropdown
}

export const InfoService = {
  getScheduleColor,
  getSubsequenceByHour,
  getIsContinueSlots,
  getGroupedSlotsBySubsequence,
  getTopPositionByCurrentTime,
  getRoomStatusIcon,
  checkIsOverlappingIntervals,
  getSortedSlots,
  getNumberOfOverlappingIntervals,
  getSortedPatientsByEndDate,
  generateDisabledTimes,
  getTimePickerOptions,
  checkAreAllHoursBetweenAvailable,
  checkAreAllMinutesBetweenAvailable,
  checkIfActionShouldBeVisible,
  getDisabledSlotsBasedOnSortedSlots,
  checkIfCurrentDateOverlapDisabledIntervals,
  isSameOrBefore,
  isSameOrAfter,
}
