import { type FC, type JSX } from 'react'
import { shallowEqual } from 'react-redux'

import classNames from 'classnames'
import type { FormikValues } from 'formik'
import { Form, Formik } from 'formik'
import { Row, Col } from 'antd'

import { Button } from 'common/components/Button/Button'
import { FormControl } from 'common/components/FormItems/FormControl/FormControl'
import {
  BUTTON_CONSTANTS,
  BUTTON_MODIFIER,
  BUTTON_PROPORTION,
} from 'common/constants/buttonConstants'
import { FORM_CONTROL_TYPE } from 'common/constants/formControlConstants'
import { INPUT_TYPES } from 'common/constants/inputConstants'
import type { IOption } from 'common/interfaces/IOption'

import { SETTINGS_BILLING_LABELS } from 'features/Profile/features/Payment/constants/constants'
import { SettingsCardInfo } from 'features/SettingsOld/components/SettingsCardInfo/SettingsCardInfo'
import {
  INITIAL_ADD_CARD_DATA,
  SETTINGS_ADD_CARD_KEYS,
  USA_COUNTRY_ID,
} from 'features/SettingsOld/constants/settingsAddCard'
import type { ISettingBilling } from 'features/SettingsOld/interfaces/ISettingBilling'
import type { ISettingPaymentCard } from 'features/SettingsOld/interfaces/ISettingPaymentCard'
import { SETTINGS_BILLING_SCHEMA } from 'features/SettingsOld/schemas/settingsBillingSchema_Square'
import { SETTINGS_EDIT_BILLING_SCHEMA } from 'features/SettingsOld/schemas/settingsEditBillingSchema'

import styles from './BillingForm.module.scss'

interface IProps {
  isRemoving: boolean
  isUpdating: boolean
  handleClose: () => void
  isLoadingStates: boolean
  isLoadingBilling: boolean
  editCard: ISettingPaymentCard
  formData: ISettingBilling | {}

  onRemove: () => Promise<void>
  locations: { states: IOption[]; countries: IOption[] }
  handleSubmit: (
    values: FormikValues,
    reset: () => void,
    setFieldError: (field: string, message: string) => void,
  ) => Promise<void>
}

export const BillingForm: FC<IProps> = ({
  isRemoving,
  onRemove,
  editCard,
  handleClose,
  formData = {},
  isLoadingBilling,
  isLoadingStates,
  handleSubmit,
  isUpdating,
  locations: { states, countries },
}) => {
  const getCountryFromList = (list: IOption[], country: string | number) => {
    return list.find((item: IOption): boolean => item.code === country || item.name === country)
  }

  return (
    <Formik
      enableReinitialize
      validateOnChange={false}
      validationSchema={editCard ? SETTINGS_EDIT_BILLING_SCHEMA : SETTINGS_BILLING_SCHEMA}
      initialValues={
        isLoadingBilling || isLoadingStates ? {} : !editCard ? INITIAL_ADD_CARD_DATA : formData
      }
      onSubmit={(values, { resetForm, setFieldError }) =>
        handleSubmit(values, resetForm, setFieldError)
      }>
      {({ values, initialValues, setFieldValue, resetForm }: any): JSX.Element => {
        const country = getCountryFromList(countries, values?.address_country)
        return (
          <Form id='billing-form' className={styles.parent}>
            <SettingsCardInfo
              editCard={editCard}
              isLoading={isLoadingBilling}
              setFieldValue={setFieldValue}
            />

            <div className={styles.parentFormItems}>
              <div className={styles.parentFormChild}>
                <h3 className={styles.parentFormDescription}>
                  {SETTINGS_BILLING_LABELS.BILLING_ADDRESS}
                </h3>
                <Row gutter={[32, 16]}>
                  <Col xs={24}>
                    <FormControl
                      required
                      type={INPUT_TYPES.TEXT}
                      isLoading={isLoadingBilling}
                      control={FORM_CONTROL_TYPE.INPUT}
                      name={SETTINGS_ADD_CARD_KEYS.NAME}
                      label={SETTINGS_BILLING_LABELS.FULL_NAME}
                    />
                  </Col>
                </Row>
                <Row gutter={[32, 16]}>
                  <Col xs={24} md={12}>
                    <FormControl
                      required
                      type={INPUT_TYPES.TEXT}
                      isLoading={isLoadingBilling}
                      control={FORM_CONTROL_TYPE.INPUT}
                      name={SETTINGS_ADD_CARD_KEYS.LINE_1}
                      label={SETTINGS_BILLING_LABELS.ADDRESS}
                    />
                  </Col>
                  <Col xs={24} md={12}>
                    <FormControl
                      type={INPUT_TYPES.TEXT}
                      isLoading={isLoadingBilling}
                      control={FORM_CONTROL_TYPE.INPUT}
                      name={SETTINGS_ADD_CARD_KEYS.LINE_2}
                      label={SETTINGS_BILLING_LABELS.APARTMENT}
                    />
                  </Col>
                </Row>
                <Row gutter={[32, 16]}>
                  <Col xs={24} md={12}>
                    <FormControl
                      required
                      options={states}
                      type={INPUT_TYPES.TEXT}
                      control={FORM_CONTROL_TYPE.SELECT}
                      name={SETTINGS_ADD_CARD_KEYS.STATE}
                      label={SETTINGS_BILLING_LABELS.STATE}
                      isLoading={isLoadingStates || isLoadingBilling}
                      disabled={country?.code !== USA_COUNTRY_ID}
                    />
                  </Col>
                  <Col xs={24} md={12}>
                    <FormControl
                      required
                      options={countries}
                      type={INPUT_TYPES.TEXT}
                      control={FORM_CONTROL_TYPE.SELECT}
                      onChange={(e) => {
                        if (e.target?.value !== USA_COUNTRY_ID) {
                          setFieldValue(SETTINGS_ADD_CARD_KEYS.STATE, null)
                        }
                      }}
                      name={SETTINGS_ADD_CARD_KEYS.COUNTRY}
                      label={SETTINGS_BILLING_LABELS.COUNTRY}
                      isLoading={isLoadingStates || isLoadingBilling}
                    />
                  </Col>
                </Row>
                <Row gutter={[32, 16]}>
                  <Col xs={24} md={12}>
                    <FormControl
                      required
                      type={INPUT_TYPES.TEXT}
                      isLoading={isLoadingBilling}
                      control={FORM_CONTROL_TYPE.INPUT}
                      name={SETTINGS_ADD_CARD_KEYS.CITY}
                      label={SETTINGS_BILLING_LABELS.CITY}
                    />
                  </Col>
                  <Col xs={24} md={12}>
                    <FormControl
                      required
                      type={INPUT_TYPES.TEXT}
                      isLoading={isLoadingBilling}
                      control={FORM_CONTROL_TYPE.INPUT}
                      name={SETTINGS_ADD_CARD_KEYS.ZIP_CODE}
                      label={SETTINGS_BILLING_LABELS.ZIP_CODE}
                    />
                  </Col>
                </Row>
              </div>
            </div>
            <Row
              align='middle'
              justify='space-between'
              gutter={[16, 32]}
              className={styles.buttonsContainer}>
              <Col
                xs={12}
                md={8}
                className={classNames('center', styles.parentDelete, {
                  [styles.parentDeleteHidden]: !editCard,
                })}>
                <Button
                  proportion={BUTTON_PROPORTION.LARGE}
                  modifier={BUTTON_MODIFIER.SECONDARY}
                  loading={isRemoving}
                  onClick={onRemove}
                  data-cy='delete-btn'>
                  {BUTTON_CONSTANTS.DELETE}
                </Button>
              </Col>
              <Col xs={12} md={8} className='center'>
                <Button
                  proportion={BUTTON_PROPORTION.LARGE}
                  modifier={BUTTON_MODIFIER.SECONDARY}
                  onClick={() => {
                    handleClose()
                    resetForm()
                  }}
                  data-cy='cancel-btn'>
                  {BUTTON_CONSTANTS.CANCEL}
                </Button>
              </Col>
              <Col xs={24} md={8} className='center'>
                <Button
                  proportion={BUTTON_PROPORTION.LARGE}
                  htmlType='submit'
                  form='billing-form'
                  loading={isUpdating}
                  data-cy='submit-btn'
                  disabled={shallowEqual(values, initialValues)}>
                  {BUTTON_CONSTANTS.SUBMIT}
                </Button>
              </Col>
            </Row>
          </Form>
        )
      }}
    </Formik>
  )
}
