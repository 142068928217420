import { useDispatch } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'

import { useAppSelector } from 'common/hooks/redux'
import type { ISiteRoom } from 'common/interfaces/ISiteRoom'

import { CartOptionFixedList } from 'features/Cart/components/CartOptionFixedList/CartOptionFixedList'
import { CartOptionSelectFixedList } from 'features/Cart/components/CartOptionSelectFixedList/CartOptionSelectFixedList'
import { CartOptionSlotList } from 'features/Cart/components/CartOptionSlotList/CartOptionSlotList'
import { CartRoomList } from 'features/Cart/components/CartRoomList/CartRoomList'
import { useCartManager } from 'features/Cart/hooks/useCartManager'
import type { ICartData, IUpdateCartPriceProps } from 'features/Cart/interfaces/ICart'
import { CartService } from 'features/Cart/services/cartService'
import {
  TOGGLE_IS_OPEN_CART_DETAILS,
  UPDATE_CART_PRICE,
} from 'features/Cart/state/slices/cartSlice'
import { SET_OVERLAY_OPTION, TOGGLE_IS_OPEN_OPTION_OVERLAY } from 'features/Option'
import { PathUtils } from 'routes/routes.utils'

const { getUpdatedCartPrice } = CartService

export const CartCardList = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const navigate = useNavigate()
  const { updateCartContent, bookingCart } = useCartManager()
  const { isOpenCart } = useAppSelector((state) => state.cartReducer)

  const handleUpdateCart = (cart: ICartData): void => {
    updateCartContent({ ...bookingCart?.data, ...cart })
  }

  const updateCartPrice = (data: IUpdateCartPriceProps): void => {
    const subtotal: number = getUpdatedCartPrice(data)
    dispatch(UPDATE_CART_PRICE({ subtotal }))
  }

  const validateBookingURL = (): void => {
    const { database_connection: siteId } = bookingCart
    const bookURL = PathUtils.getBook(siteId)
    if (siteId && location.pathname !== bookURL) navigate(bookURL)
  }

  const handleEditOption = (option: ISiteRoom): void => {
    dispatch(SET_OVERLAY_OPTION(option))
    if (isOpenCart) dispatch(TOGGLE_IS_OPEN_CART_DETAILS())

    dispatch(TOGGLE_IS_OPEN_OPTION_OVERLAY())
    validateBookingURL()
  }

  return (
    <>
      <CartRoomList
        handleUpdate={handleUpdateCart}
        updateCartPrice={updateCartPrice}
        validateBookingURL={validateBookingURL}
      />
      <CartOptionSlotList
        handleEdit={handleEditOption}
        handleUpdate={handleUpdateCart}
        updateCartPrice={updateCartPrice}
      />
      <CartOptionFixedList
        handleEdit={handleEditOption}
        handleUpdate={handleUpdateCart}
        updateCartPrice={updateCartPrice}
      />
      <CartOptionSelectFixedList
        handleEdit={handleEditOption}
        handleUpdate={handleUpdateCart}
        updateCartPrice={updateCartPrice}
      />
    </>
  )
}
