import type { FC, ReactNode } from 'react'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import styles from './minimap.module.scss'

interface MinimapProps {
  onScroll: (scrollLeft: number) => void
  containerWidth: number
  contentWidth: number
  contentScroll?: number
  backgroundElement?: ReactNode
}

const viewPortPadding = 20

export const Minimap: FC<MinimapProps> = ({
  onScroll,
  containerWidth,
  contentWidth,
  contentScroll,
  backgroundElement,
}) => {
  const [isDragging, setIsDragging] = useState(false)
  const [startX, setStartX] = useState(0)
  const [scrollLeft, setScrollLeft] = useState(0)

  const minimapRef = useRef<HTMLDivElement>(null)

  const handleMouseDown = (e: React.MouseEvent) => {
    e.preventDefault()
    setIsDragging(true)
    setStartX(e.pageX - (minimapRef.current?.offsetLeft || 0))
  }

  const handleMouseUp = () => {
    setIsDragging(false)
  }

  const handleMouseMove = (e: React.MouseEvent) => {
    if (!isDragging) {
      return
    }
    e.preventDefault()

    const x = e.pageX - (minimapRef.current?.offsetLeft || 0)
    const walk = (x - startX) * (contentWidth / containerWidth)
    const newScrollLeft = Math.max(0, Math.min(scrollLeft + walk, contentWidth - containerWidth))
    setScrollLeft(newScrollLeft)
    onScroll(newScrollLeft)
  }

  useEffect(() => {
    document.addEventListener('mouseup', handleMouseUp)

    return () => document.removeEventListener('mouseup', handleMouseUp)
  }, [])

  useEffect(() => {
    setScrollLeft(contentScroll)
  }, [contentScroll])

  const viewportWidth = useMemo(
    () => (containerWidth / contentWidth) * 100,
    [containerWidth, contentWidth],
  )

  const viewportLeft = useMemo(() => (scrollLeft / contentWidth) * 100, [scrollLeft, contentWidth])

  return (
    <>
      {viewportWidth <= 100 && (
        <div
          ref={minimapRef}
          className={styles.minimap}
          onMouseDown={handleMouseDown}
          onMouseMove={handleMouseMove}
          onDragStart={(e) => e.preventDefault()}>
          {backgroundElement}

          <div
            className={styles.viewport}
            style={{
              width: `calc(${viewportWidth}% - ${viewPortPadding}px)`,
              left: `${viewportLeft}%`,
            }}
          />
        </div>
      )}
    </>
  )
}
