import { Skeleton } from 'antd'

type Props = {
  skeletonRooms: number
}

export const RoomLoadingSkeleton = ({ skeletonRooms }: Props) => {
  return (
    <div className='skeleton-container'>
      {Array.from({ length: skeletonRooms }).map((_, ind) => {
        return (
          <div className='room-skeleton' key={ind}>
            <Skeleton.Image active className='room-skeleton-img' />
            {Array.from({ length: 3 }).map((_, index) => {
              return (
                <div key={index} className='room-skeleton-timeslot'>
                  <Skeleton.Button active className='checkbox-skeleton' />
                  <Skeleton.Input active className='input-skeleton' />
                </div>
              )
            })}
          </div>
        )
      })}
    </div>
  )
}
