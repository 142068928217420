import { Tooltip } from 'antd'

import { UtilService } from 'common/services/utilService'
import dayjs from 'dayjs'

const defaultFailedDetails = `This payment failed because the customer 
used an invalid card or account number. 
Find more details on the payment timeline.`

export const paymentHistoryColumns = [
  {
    title: 'Credit Card',
    dataIndex: 'card_last',
    key: 'card_last',
    type: 'text',
    render: (card_last: number) => <p className='m-0'>•••• {card_last}</p>,
    sorter: true,
  },
  {
    title: 'Payment',
    dataIndex: 'transaction_description',
    key: 'transaction_description',
    type: 'text',
    sorter: true,
  },

  {
    title: 'Amount',
    dataIndex: 'amount',
    key: 'amount',
    type: 'text',
    render: (amount: number) => UtilService.numberToDollar(Number(amount)),
    sorter: true,
  },

  {
    title: 'Date',
    dataIndex: 'created_at',
    key: 'created_at',
    type: 'text',
    render: (created_at: string) => dayjs(created_at).format("ddd, MMM DD 'YY"),
    sorter: true,
  },
  {
    title: 'Status',
    dataIndex: 'transaction_status',
    key: 'transaction_status',
    type: 'text',
    render: (status: string) => (
      <Tooltip title={status === 'failed' ? defaultFailedDetails : ''} placement='left'>
        <p className='payment-status' data-status={status}>
          {status}
        </p>
      </Tooltip>
    ),
    sorter: true,
  },
]
