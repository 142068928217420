import { Checkbox } from 'antd'
import styles from './bookingSlot.module.scss'
import { formatMoney } from 'common/services/format'
import type { BaseSlot } from 'features/Booking/components/BookingSlots/BookingSlotGroup'

interface Props<T extends BaseSlot> {
  slot: T
  isSelected: boolean
  onToggle: () => void
  isDisabled?: boolean
}

export const BookingSlot = <T extends BaseSlot>({
  slot,
  isSelected,
  onToggle,
  isDisabled: isDisabledProp,
}: Props<T>) => {
  const isActive = !!slot.is_active
  const isDisabled = isDisabledProp || !isActive
  return (
    <div
      className={styles.slot}
      data-selected={isSelected}
      data-is-active={!isDisabled}
      onClick={onToggle}>
      <Checkbox disabled={isDisabled} checked={isSelected} onChange={onToggle} />
      <span className={styles.slotTitle}>{slot.title}</span>
      <span className={styles.slotPrice}>
        {slot?.price >= 0 && <> {formatMoney(slot.price)} </>}
      </span>
    </div>
  )
}
