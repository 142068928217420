/* eslint-disable @typescript-eslint/no-unused-vars */

import { UtilService } from 'common/services/utilService'

import type {
  IBookingInfoRoom,
  IRoomOptionProduct,
  IRoomSelectedDay,
  IRoomSelectedTimeSlot,
  IRoomTimeSlot,
} from 'features/Booking/interfaces/ILeaseInfoRoom'
import type { IOptionSlot } from 'features/Option'
import { OptionService } from 'features/Option/services/optionService'

const { structuredClone } = UtilService
const { getFilteredDaysByDate } = OptionService

const getDayInSelectedDays = (selectedDays: IRoomSelectedDay[], date: string): IRoomSelectedDay =>
  selectedDays.find((reservedDay: IRoomSelectedDay): boolean => reservedDay.date === date)

const getUpdatedRoom = (slotId: number, date: string, roomLeaseInfo: IBookingInfoRoom) => {
  const copyRoomLeaseInfo: IBookingInfoRoom = structuredClone<IBookingInfoRoom>(roomLeaseInfo)
  const removeSlotDayInfo: IRoomSelectedDay = getDayInSelectedDays(
    copyRoomLeaseInfo.selectedDays,
    date,
  )
  if (removeSlotDayInfo.selectedSlots.length === 1)
    copyRoomLeaseInfo.selectedDays = getFilteredDaysByDate<IRoomSelectedDay>(
      copyRoomLeaseInfo.selectedDays,
      date,
    )
  else {
    const updatedSlots = removeSlotDayInfo.selectedSlots.filter(
      (reservedSlot: IRoomSelectedTimeSlot): boolean => {
        return reservedSlot.id !== slotId
      },
    )
    if (copyRoomLeaseInfo?.selectedDay) copyRoomLeaseInfo.selectedDay.selectedSlots = updatedSlots

    copyRoomLeaseInfo.selectedDays = copyRoomLeaseInfo.selectedDays.map(
      (reservedDay: IRoomSelectedDay): IRoomSelectedDay =>
        reservedDay.date === removeSlotDayInfo.date
          ? { ...removeSlotDayInfo, selectedSlots: updatedSlots }
          : reservedDay,
    )
  }
  return copyRoomLeaseInfo
}

const getUpdatedLease = (
  newLeaseInfo: IBookingInfoRoom,
  newLeaseRooms: IBookingInfoRoom[],
): IBookingInfoRoom[] => {
  const { room, selectedDays } = newLeaseInfo
  let copyLeaseRooms: IBookingInfoRoom[] = structuredClone<IBookingInfoRoom[]>(newLeaseRooms)
  // The last room reserved for rent with the same id with the same new reservation
  const reservedLease: IBookingInfoRoom | undefined = copyLeaseRooms
    .filter((reservedLease: IBookingInfoRoom): boolean => reservedLease.room.id === room.id)
    .at(-1)
  if (reservedLease) {
    if (!selectedDays.length) {
      //If there are no more days in reservations for the room, delete the
      // room and change the order id of the other rooms with the same id
      copyLeaseRooms = copyLeaseRooms
        .filter(
          (leaseInfo: IBookingInfoRoom): boolean =>
            leaseInfo.id !== newLeaseInfo.id || leaseInfo.room.id !== room.id,
        )
        .map(
          (leaseInfo: IBookingInfoRoom): IBookingInfoRoom =>
            leaseInfo.room.id === room.id && newLeaseInfo.id < leaseInfo.id
              ? { ...leaseInfo, id: leaseInfo.id - 1 }
              : leaseInfo,
        )
    } else if (!newLeaseInfo.id) copyLeaseRooms.push({ ...newLeaseInfo, id: reservedLease.id + 1 })
    else {
      // If it's just a change, replace the data with the new ones
      copyLeaseRooms = copyLeaseRooms.map(
        (reservedLease: IBookingInfoRoom): IBookingInfoRoom =>
          reservedLease.id === newLeaseInfo.id && reservedLease.room.id === room.id && reservedLease
            ? newLeaseInfo
            : reservedLease,
      )
    }
  } else copyLeaseRooms.push({ ...newLeaseInfo, id: 1 })
  return getUpdatedRoomsPrice(copyLeaseRooms)
}

const getLeaseRoomsPrice = (days: IRoomSelectedDay[]): number =>
  days.reduce((totalPrice: number, currentDate: IRoomSelectedDay) => {
    const currentDatePrice: number = getLeasePriceBySlots(currentDate?.selectedSlots)
    return totalPrice + currentDatePrice
  }, 0)

export const getLeasePriceBySlots = (slots: IRoomSelectedTimeSlot[]): number => {
  const price: number = slots.reduce((slotTotal: number, slot: IRoomSelectedTimeSlot) => {
    const slotPrice = slot.price
    //  TODO: @REMOVE_OPTIONS
    const optionsPrice = slot.selectedOptions?.length
      ? slot.selectedOptions.reduce((optionTotal: number, option: IRoomOptionProduct) => {
          return optionTotal + option.price * (option?.stock || 1)
        }, 0)
      : 0
    return slotTotal + slotPrice + optionsPrice
  }, 0)

  return price
}

const getRoomById = (rooms: IBookingInfoRoom[], id: number): IBookingInfoRoom =>
  rooms.find((room: IBookingInfoRoom): boolean => room.id === id)

const getUpdatedRoomsPrice = (rooms: IBookingInfoRoom[]): IBookingInfoRoom[] =>
  rooms.map(
    (lease: IBookingInfoRoom): IBookingInfoRoom => ({
      ...lease,
      price: getLeaseRoomsPrice(lease.selectedDays),
    }),
  )

const updateSiteOptionsVisibility = (visible: boolean, options: IRoomOptionProduct[]) => {
  if (!Array.isArray(options)) return []
  const adjustedOptions: IRoomOptionProduct[] = options.map(
    (product: IRoomOptionProduct): IRoomOptionProduct => ({
      ...product,
      is_visible: !product.is_visible || visible ? 0 : 1,
    }),
  )

  return adjustedOptions
}

const getReservationSelectedSlot = (
  slots: IRoomSelectedTimeSlot[],
  slot: IRoomTimeSlot,
): IRoomSelectedTimeSlot | null => {
  if (slot) {
    const activeTimeSlot: IRoomSelectedTimeSlot | undefined = slots?.find(
      ({ id }: IRoomSelectedTimeSlot): boolean => id === slot.id,
    )
    if (activeTimeSlot) return activeTimeSlot
    else
      return {
        ...slot,
        selectedOptions: [],
      }
  }
  return null
}

export const makeLeaseRoomsGreatAgain = (leaseRooms: IBookingInfoRoom[]) => {
  return leaseRooms.map(deleteRoomGarbage)
}

const deleteRoomGarbage = (leaseRoom: IBookingInfoRoom) => {
  const { price, id, room, selectedDays, ...rest } = leaseRoom

  const updatedRoom = {
    id: room.id,
    description: room.description,
    images: room.images,
    name: room.name,
  }

  const updatedSelectedDays = selectedDays.map((day: IRoomSelectedDay) => {
    return {
      date: day.date,
      selectedSlots: day.selectedSlots.map((slot: IRoomSelectedTimeSlot) => {
        const { options, selectedOptions, now, ...rest } = slot
        return {
          ...rest,
        }
      }),
    }
  })

  return {
    price,
    id,
    room: updatedRoom,
    selectedDays: updatedSelectedDays,
  }
}

export const makeOptionsGreatAgain = (slots: IOptionSlot[]) => {
  return slots.map(deleteOptionGarbage)
}

const deleteOptionGarbage = (slot: IOptionSlot): IOptionSlot => {
  const { option, price, selectedDays } = slot

  const updatedSelectedDays = selectedDays.map(({ slots, ...selectedDays }) => {
    return {
      ...selectedDays,
    }
  })

  return {
    option,
    price,
    selectedDays: updatedSelectedDays,
  }
}

export const LeaseService = {
  getRoomById,
  getUpdatedLease,
  getUpdatedRoom,
  getUpdatedRoomsPrice,
  getLeaseRoomsPrice,
  getDayInSelectedDays,
  getLeasePriceBySlots,
  getReservationSelectedSlot,
  updateSiteOptionsVisibility,
}
