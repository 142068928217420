import type { FC } from 'react'
import React from 'react'

import classNames from 'classnames'

import { ReactComponent as BoxIcon } from 'assets/icons/box.svg'
import { ReactComponent as DimensionIcon } from 'assets/icons/dimension.svg'

import { Show } from 'common/components/Show/Show'
import { TagStatus } from 'common/components/TagStatus/TagStatus'
import { ALT_CONSTANTS } from 'common/constants/altConstants'
import { APP_CONSTANTS } from 'common/constants/constants'
import { UtilService } from 'common/services/utilService'
import type { TAG_STATUS } from 'common/types/tagStatusType'

import { BOOKING_TYPE_CODE } from 'features/Booking/constants/booking.constants'

import styles from './bookingThumbnail.module.scss'
import type { BookingThumbnailProps } from './bookingThumbnail.types'

export const BookingThumbnail: FC<BookingThumbnailProps> = ({
  booking,
  withPrice,
  navigateToBookingHandler,
}) => {
  return (
    <div
      className={styles.cardContainer}
      data-cy={`booking-${booking.id}`}
      onClick={() => navigateToBookingHandler(booking)}>
      <button className={styles.image}>
        <img
          src={booking.image}
          className={styles.photo}
          alt={ALT_CONSTANTS.ACTIVE_BOOKING_IMAGE}
          onError={(e) => {
            const target = e.target as HTMLImageElement
            target.onerror = null
            target.src =
              'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7'
          }}
        />
        <div className={styles.info}>
          <span>
            <span className={styles.bookingId}>{booking.id}</span>
            <p
              className={classNames(styles.contentLocation, {
                [styles.title]: !withPrice,
              })}>
              {booking.site_name}
            </p>
          </span>
          <Show when={withPrice}>
            <p className={styles.bookingType}>
              {booking.is_promotion
                ? 'Promotion'
                : booking.lease_type_name.split('(').map((item, index, arr) => (
                    <React.Fragment key={index}>
                      {index !== 0 && <br />}
                      <span>{(index === 1 && arr.length > 1 ? '(' : '') + item}</span>
                    </React.Fragment>
                  ))}
            </p>
          </Show>
        </div>
        <div className={styles.boxValue}>
          <TagStatus status={UtilService.capitalizeFirstLetter(booking.status) as TAG_STATUS} />
          {booking.hardware_identifier && (
            <span className={styles.valueContainer}>
              <span className={styles.value}>ID:{booking?.hardware_identifier}</span>
            </span>
          )}
          {booking.lease_type_code === BOOKING_TYPE_CODE.MAILBOX && (
            <span className={styles.valueContainer}>
              <BoxIcon />
              <span className={styles.value}>{booking?.box_number}</span>
            </span>
          )}
          {booking.lease_type_code === BOOKING_TYPE_CODE.STORAGE && (
            <span className={styles.valueContainer}>
              <DimensionIcon />
              <span
                className={styles.value}>{`${booking.no_of_units} x ${booking?.unit_info}`}</span>
            </span>
          )}
        </div>
      </button>
      <div className={styles.footer}>
        <span className={styles.footerDate}>
          <span className={styles.footerDateType}>{APP_CONSTANTS.STARTS}</span>
          {booking.start_date}
        </span>
        <span className={styles.footerDate}>
          <span className={styles.footerDateType}>{APP_CONSTANTS.ENDS}</span>
          {booking.end_date}
        </span>
      </div>
    </div>
  )
}
