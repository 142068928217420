import type { FC } from 'react'
import { useMemo } from 'react'

import { useAuth } from 'app/providers'

import { AddToCartButton } from 'common/components/AddToCartButton/AddToCartButton'
import { Button } from 'common/components/Button/Button'
import {
  BUTTON_CONSTANTS,
  BUTTON_MODIFIER,
  BUTTON_PROPORTION,
  BUTTON_SEVERITY,
} from 'common/constants/buttonConstants'
import { useAppDispatch, useAppSelector } from 'common/hooks/redux'

import type { IRoomSelectedDay } from 'features/Booking/interfaces/ILeaseInfoRoom'
import { LeaseBackToBackService } from 'features/Booking/services/leaseBackToBackService'
import {
  UPDATE_NEW_LEASE_ROOM_DAYS,
  UPDATE_NEW_LEASE_SELECTED_DAY,
} from 'features/Booking/state/slices/leaseSlice'
import useUnconfirmedPopup from 'features/Home/hooks/useUnconfirmedPopup'
import { useCartHasChanges } from 'features/Booking/components/LeaseContent/useCartHasChanges'
import { useDisabledAddToCart } from 'features/Booking/components/LeaseContent/useDisabledAddToCart'

interface IProps {
  price: number
  isOneHourSlot: boolean
  handleCreateLeaseAndClose: () => void
  handleCreateLeaseAndCheckout: () => void
}

const { updateRoomServicePlacement, updateDaySlots } = LeaseBackToBackService

export const LeaseSubmit: FC<IProps> = ({
  handleCreateLeaseAndCheckout,
  handleCreateLeaseAndClose,
  price,
}) => {
  const dispatch = useAppDispatch()
  const { user, isDoctor, hasCompletedDocuments } = useAuth()
  const { showPopup } = useUnconfirmedPopup()

  const hasCartChanges = useCartHasChanges()

  const isUnconfirmedDoctor = useMemo(
    () => isDoctor && !hasCompletedDocuments,
    [isDoctor, hasCompletedDocuments],
  )

  const cantSaveLease = useMemo(() => !user || isUnconfirmedDoctor, [user, isUnconfirmedDoctor])

  const isDisabledAddToCart = useDisabledAddToCart()

  const { newLeaseInfo: newRoom } = useAppSelector((state) => state.leaseReducer)

  const { selectedDay } = newRoom

  const handleSaveSelectedTimeSlot = (): void => {
    if (cantSaveLease) {
      return showPopup()
    }

    let updatedDay: IRoomSelectedDay = updateDaySlots(selectedDay)
    updatedDay = updateRoomServicePlacement(updatedDay)
    dispatch(UPDATE_NEW_LEASE_SELECTED_DAY(updatedDay))
    dispatch(UPDATE_NEW_LEASE_ROOM_DAYS())
  }

  return (
    <>
      <AddToCartButton
        price={price}
        data-cy='apply-changes-btn'
        onClick={handleSaveSelectedTimeSlot}
        disabled={!hasCartChanges}
      />
      {user && (
        <>
          <Button
            onClick={handleCreateLeaseAndClose}
            data-cy='add-to-cart-btn'
            disabled={isDisabledAddToCart}
            modifier={BUTTON_MODIFIER.TERTIARY80}
            proportion={BUTTON_PROPORTION.LARGE}>
            {BUTTON_CONSTANTS.CONTINUE}
          </Button>
          <Button
            onClick={handleCreateLeaseAndCheckout}
            data-cy='checkout-btn'
            disabled={isDisabledAddToCart}
            modifier={BUTTON_MODIFIER.PRIMARY}
            severity={BUTTON_SEVERITY.SUCCESS_FILLED}
            proportion={BUTTON_PROPORTION.LARGE}>
            {BUTTON_CONSTANTS.CHECKOUT}
          </Button>
        </>
      )}
    </>
  )
}
