import { useAppDispatch, useAppSelector } from 'common/hooks/redux'
import { LeaseSlotSkeletonList } from 'features/Booking/components/LeaseSlotSkeletonList/LeaseSlotSkeletonList'
import { useFetchRoomSlotsQuery } from 'features/Booking/state/api/bookingApi'
import { useParams } from 'react-router-dom'
import { useEffect, useMemo, useState } from 'react'
import styles from './bookingSlots.module.scss'
import { type CalculatedDiscount, calculateTotalDiscount, groupSlots } from './booking.utils'
import { BookingSlotGroup } from './BookingSlotGroup'
import { formatPercentage } from 'common/services/format'
import type { IDiscount, IRoomTimeSlot } from 'features/Booking/interfaces/ILeaseInfoRoom'
import { SET_NEW_LEASE_SELECTED_SLOTS } from 'features/Booking/state/slices/leaseSlice'
import { ReactComponent as Bird } from 'assets/images/cart-bird.svg'

type Props = {
  isLoading?: boolean
  date: string
  defaultSelectedSlotsIds: number[]
  setCalculatedDiscount: (discount: CalculatedDiscount) => void
}

const EMPTY_SLOTS: IRoomTimeSlot[] = []
const EMPTY_DISCOUNT: IDiscount[] = []
const EMPTY_SELECTED_SLOTS_IDS: number[] = []

const BookingSlots = ({
  isLoading,
  date,
  defaultSelectedSlotsIds = EMPTY_SELECTED_SLOTS_IDS,
  setCalculatedDiscount,
}: Props) => {
  const dispatch = useAppDispatch()
  const { siteId } = useParams()
  const { newLeaseInfo } = useAppSelector((state) => state.leaseReducer)

  const { data, isFetching: isFetchingSlots } = useFetchRoomSlotsQuery(
    {
      roomId: newLeaseInfo.room.id,
      date,
      siteId,
    },
    {
      skip: !newLeaseInfo?.room?.id || !date,
    },
  )

  const { slots = EMPTY_SLOTS, discount = EMPTY_DISCOUNT } = data ?? {}

  const [selectedSlotIds, setSelectedSlotIds] = useState<number[]>(defaultSelectedSlotsIds)

  const handleUpdateSelectedSlots = (slotsSet: Set<number>) => {
    const slotsToAdd = Array.from(slotsSet).sort((a, b) => a - b)

    const selectedSlotsWithData = slotsToAdd.map((slotId) =>
      slots.find((slot) => slot.id === slotId),
    )
    dispatch(SET_NEW_LEASE_SELECTED_SLOTS(selectedSlotsWithData))
    setSelectedSlotIds(slotsToAdd)
  }

  const handleSlotToggle = (slotId: number) => {
    const prevSelectedSlotIds = new Set(selectedSlotIds)

    if (prevSelectedSlotIds.has(slotId)) {
      prevSelectedSlotIds.delete(slotId)
    } else {
      prevSelectedSlotIds.add(slotId)
    }
    handleUpdateSelectedSlots(prevSelectedSlotIds)
  }

  const handleGroupToggle = (groupSlotIds: number[]) => {
    const prevSelectedSlotIds = new Set(selectedSlotIds)

    const allSelected = groupSlotIds.every((id) => prevSelectedSlotIds.has(id))
    if (allSelected) {
      groupSlotIds.forEach((id) => prevSelectedSlotIds.delete(id))
    } else {
      groupSlotIds.forEach((id) => prevSelectedSlotIds.add(id))
    }

    handleUpdateSelectedSlots(prevSelectedSlotIds)
  }
  const calculatedDiscount = useMemo(() => {
    return calculateTotalDiscount(selectedSlotIds, slots, discount)
  }, [selectedSlotIds, slots, discount])

  const groupedSlots = useMemo(() => {
    return groupSlots(slots)
  }, [slots])

  const formattedSlots = useMemo(() => {
    return new Intl.ListFormat('en-US', { style: 'short', type: 'disjunction' }).format(
      discount.map(({ slots }) => slots.toString()),
    )
  }, [discount])

  const formattedPercentage = useMemo(() => {
    return discount
      .map(({ value }, index) => (index === discount.length - 1 ? formatPercentage(value) : value))
      .join(', ')
  }, [discount])

  useEffect(() => {
    setCalculatedDiscount(calculatedDiscount)
  }, [calculatedDiscount.totalDiscount])

  useEffect(() => {
    setSelectedSlotIds(defaultSelectedSlotsIds)
  }, [defaultSelectedSlotsIds])

  if (isLoading || isFetchingSlots) return <LeaseSlotSkeletonList total={3} />

  return (
    <div className={styles.container}>
      {Object.entries(groupedSlots).map(([groupName, slots]) => (
        <BookingSlotGroup
          key={groupName}
          hasIntervalTitle
          groupName={groupName}
          slots={slots}
          selectedSlots={selectedSlotIds}
          onSlotToggle={handleSlotToggle}
          onGroupToggle={handleGroupToggle}
        />
      ))}
      <div className={styles.discountsContainer}>
        <div className={styles.discountIcon}>
          <Bird />
        </div>
        <div className={styles.discountLabel}>
          Select {formattedSlots} consecutive slots {'\n'} and get {formattedPercentage} off,{' '}
          respectively.
        </div>
      </div>
    </div>
  )
}

export default BookingSlots
