import { createApi } from '@reduxjs/toolkit/query/react'
import type { BaseQueryFn } from '@reduxjs/toolkit/src/query/baseQueryTypes'
import type { EndpointBuilder } from '@reduxjs/toolkit/src/query/endpointDefinitions'

import type { ISiteRoom } from 'common/interfaces/ISiteRoom'
import type { TableParamsType } from 'common/interfaces/ITable'
import { UtilService } from 'common/services/utilService'
import { baseQuery } from 'common/store/api/fetchBaseQuery'
import { REDUCERS } from 'common/store/reducerConstants'

import type { BOOKING_TYPE } from 'features/Booking/constants/bookingType'
import type {
  BookingOption,
  BookingOptionTypes,
  IBooking,
} from 'features/Booking/interfaces/IBooking'
import type { TimeSlot } from 'features/Booking/interfaces/ILeaseInfoRoom'

const { withPaginationParams } = UtilService

export enum BOOKING_TAGS {
  BOOKINGS = 'Bookings',
}

export const bookingApi = createApi({
  reducerPath: REDUCERS.BOOKING,
  baseQuery: baseQuery(),
  endpoints: (build: EndpointBuilder<BaseQueryFn, string, string>) => ({
    fetchBookings: build.query<IBooking[], { type: BOOKING_TYPE; siteId: string }>({
      query: ({ type, siteId }) => ({
        url: `/leases?filter=${type}`,
        headers: {
          'Database-Connection': siteId,
        },
      }),
      providesTags: [BOOKING_TAGS.BOOKINGS],
      transformResponse: (data: { items: IBooking[] }): IBooking[] => data.items,
    }),

    fetchBookingsWithPagination: build.query<
      {
        items: IBooking[]
        total_items: number
      },
      {
        type: BOOKING_TYPE
        tableParams: TableParamsType
        siteId: string
      }
    >({
      query: ({ siteId, type, tableParams }) => {
        let url = withPaginationParams('leases', tableParams) + `&filter=${type}`
        return {
          url,
          headers: {
            'Database-Connection': siteId,
          },
        }
      },
      providesTags: [BOOKING_TAGS.BOOKINGS],
    }),

    fetchBookingOption: build.query<BookingOption, { request_url_id: number; siteId: string }>({
      query: ({ request_url_id, siteId }) => ({
        url: `/booking/${request_url_id}/option`,
        headers: {
          'Database-Connection': siteId,
        },
      }),
      providesTags: [BOOKING_TAGS.BOOKINGS],
    }),

    fetchBookingTypes: build.query<BookingOptionTypes, string>({
      query: (siteId) => ({
        url: '/booking/types',
        headers: {
          'Database-Connection': siteId,
        },
      }),
      providesTags: [BOOKING_TAGS.BOOKINGS],
    }),
    fetchRooms: build.query<
      {
        rooms: ISiteRoom[]
        site_options: ISiteRoom[]
        medical_staff: ISiteRoom[]
      },
      string
    >({
      query: (siteId) => ({
        url: '/newlease',
        headers: {
          'Database-Connection': siteId,
        },
      }),
    }),
    fetchRoomSlots: build.query<TimeSlot, { roomId: number; date: string; siteId: string }>({
      query: ({ roomId, date, siteId }) => ({
        url: `/lease/room/${roomId}/day-slots/${date}`,
        headers: { 'Database-Connection': siteId },
      }),
    }),
    fetchRoomDays: build.query({
      query: ({ id, date, siteId }) => ({
        url: `/lease/room/${id}/calendar/${date}`,
        headers: { 'Database-Connection': siteId },
      }),
    }),
  }),
})

export const {
  useLazyFetchRoomSlotsQuery,
  useFetchRoomSlotsQuery,
  useLazyFetchRoomDaysQuery,
  useFetchRoomDaysQuery,
  useLazyFetchRoomsQuery,
  useFetchBookingsQuery,
  useLazyFetchBookingsQuery,
  useLazyFetchBookingTypesQuery,
  useFetchBookingTypesQuery,
  useFetchBookingOptionQuery,
  useLazyFetchBookingOptionQuery,
  useLazyFetchBookingsWithPaginationQuery,
} = bookingApi
