import type { FC } from 'react'
import { memo, useEffect, useState } from 'react'
import { useInterval } from 'react-use'

import { Tooltip } from 'antd'

import patientIcon from 'features/Home/assets/patient.svg'

import { SuffixSelect } from 'common/components/Select'
import { Show } from 'common/components/Show/Show'
import { BUTTON_MODIFIER } from 'common/constants/buttonConstants'
import { useAppSelector } from 'common/hooks/redux'
import {
  CHECK_FOR_DISABLED_CHANGE_ROOM_STATUS_INTERVAL,
  INFO_CONSTANTS,
  ROOM_STATUS,
} from 'features/Home/constants/infoConstants'
import { InfoService } from 'features/Home/services/infoService'

import styles from './documentResourceHeader.module.scss'
import type { DocumentResourceHeaderProps } from './documentResourceHeader.types'

export const DocumentResourceHeader: FC<DocumentResourceHeaderProps> = memo(
  ({
    room,
    hasRoomStatus = true,
    doctorsBufferTime,
    statusList = [],
    updateStatus = () => {},
    timeZone,
    patientsCount,
  }) => {
    const { isUpdatingRoomStatus, disabledIntervals, dayInterval } = useAppSelector(
      (state) => state.bookReducer,
    )
    const [dropdownDisabled, setDropDownDisabled] = useState(false)
    const getIsDisabledDropdown = () => {
      return InfoService.checkIfCurrentDateOverlapDisabledIntervals(
        disabledIntervals,
        room,
        dayInterval,
        timeZone,
        doctorsBufferTime,
      )
    }

    useInterval(() => {
      setDropDownDisabled(getIsDisabledDropdown())
    }, CHECK_FOR_DISABLED_CHANGE_ROOM_STATUS_INTERVAL)

    useEffect(() => {
      setDropDownDisabled(getIsDisabledDropdown())
    }, [room])

    const patientNumber = (() => {
      if (patientsCount >= 0) return patientsCount
      return room.max_patients || 1
    })()

    return (
      <div className={styles.parent}>
        <div className={styles.parentHeader}>
          <h2 className={styles.parentRoomName}>{room.label}</h2>
          <Tooltip
            title={
              room.is_waiting_room
                ? INFO_CONSTANTS.CURRENT_PATIENTS_IN_WAITING_ROOM
                : INFO_CONSTANTS.SAME_TIME_ALLOWED_PATIENTS
            }
            placement='topLeft'>
            <div className={styles.parentPatientNumberContainer}>
              <img
                className={styles.parentPatientNumberIcon}
                src={patientIcon}
                alt='patient allowed slots'
              />
              <span className={styles.parentPatientNumber}>{patientNumber}</span>
            </div>
          </Tooltip>
        </div>
        <Show when={hasRoomStatus}>
          <div className={styles.parentRoomSelect}>
            <SuffixSelect
              isLoading={isUpdatingRoomStatus}
              listOptions={statusList}
              title='Room status'
              value={dropdownDisabled ? 'N/A' : room?.status?.code}
              onChange={(value) => updateStatus(value, room)}
              icon={InfoService.getRoomStatusIcon(
                dropdownDisabled ? ROOM_STATUS.DISABLE : room?.status?.code || ROOM_STATUS.BUSY,
              )}
              modifier={BUTTON_MODIFIER.SECONDARY}
              disabled={dropdownDisabled}
            />
          </div>
        </Show>
      </div>
    )
  },
)
