import { useMemo } from 'react'

import { UtilService } from 'common/services/utilService'
import type { IBookingInfoRoom } from 'features/Booking/interfaces/ILeaseInfoRoom'
import { useAppSelector } from 'common/hooks/redux'
import { useCartManager } from 'features/Cart/hooks/useCartManager'

const { deepEqual, arrDeepEqual } = UtilService

export const useDisabledAddToCart = () => {
  try {
    const { bookingCart } = useCartManager()
    const { newLeaseInfo: newRoom } = useAppSelector((state) => state.leaseReducer)

    const { selectedDays = [], selectedDay } = newRoom
    const { rooms: reservedRooms = [] } = bookingCart?.data ?? {}
    const currentRoom: IBookingInfoRoom = useMemo((): IBookingInfoRoom => {
      return reservedRooms.find(
        ({ room }: IBookingInfoRoom): boolean => room.id === newRoom?.room?.id,
      )
    }, [reservedRooms, newRoom])

    const isCreatingDisabled: boolean = !currentRoom && !selectedDays.length
    const isUpdatingDisabled: boolean =
      currentRoom && arrDeepEqual(selectedDays, currentRoom.selectedDays)

    const hasSelectedSlots = selectedDays.some((day) => day?.selectedSlots?.length)

    const isDisabledAddToCart: boolean =
      (currentRoom &&
        deepEqual(currentRoom, newRoom, [
          'slots',
          'free_seat',
          'selectedDay',
          'description',
          'room_counter',
        ])) ||
      isCreatingDisabled ||
      isUpdatingDisabled ||
      !hasSelectedSlots

    return isDisabledAddToCart
  } catch (error) {
    return true
  }
}
