import { Skeleton } from 'antd'

import { ArrayElements } from 'common/components/ArrayElements/ArrayElements'

import styles from './leaseSlotSkeletonList.module.scss'

type Props = {
  total?: number
}

export const LeaseSlotSkeletonList = ({ total = 3 }: Props) => (
  <div className={styles.container}>
    <ArrayElements total={total}>
      <Skeleton.Button className={styles.parent} active block />
    </ArrayElements>
  </div>
)
