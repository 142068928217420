import { createApi } from '@reduxjs/toolkit/query/react'
import type { BaseQueryFn } from '@reduxjs/toolkit/src/query/baseQueryTypes'
import type { EndpointBuilder } from '@reduxjs/toolkit/src/query/endpointDefinitions'

import { HTTP_METHODS } from 'common/constants/httpMethodsConstants'
import type { ApiConnection } from 'common/interfaces/api'
import type { IRequestData } from 'common/interfaces/IRequestResponse'
import type { TableParamsType } from 'common/interfaces/ITable'
import { UtilService } from 'common/services/utilService'
import { baseQuery } from 'common/store/api/fetchBaseQuery'
import { REDUCERS } from 'common/store/reducerConstants'

import type { IBooking } from 'features/Booking'
import type { CheckoutDTO } from 'features/Cart/interfaces/ICart'
import type { IBookingNotification } from 'features/Home/interfaces/IInfo'
import type { IInfoDocument } from 'features/Home/interfaces/IInfoDocument'
import type {
  PromotionBookingsType,
  PromotionBookingType,
  PromotionMonth,
  PromotionRoomAvailability,
  PromotionSignContracts,
  PromotionType,
} from 'features/Home/Promotion/types/promotion.types'

const { withPaginationParams } = UtilService

export const promotionsApi = createApi({
  reducerPath: REDUCERS.PROMOTIONS,
  baseQuery: baseQuery(),
  tagTypes: ['Promotions', 'RoomAvailability', 'Bookings'],
  endpoints: (build: EndpointBuilder<BaseQueryFn, string, string>) => ({
    fetchPromotionsInfo: build.query<PromotionType, ApiConnection>({
      query: ({ siteId, bookingId }) => ({
        url: `/promotion/${bookingId}`,
        headers: { 'Database-Connection': siteId },
      }),
      providesTags: ['Promotions'],
      transformResponse: (response: { item: PromotionType }) => response.item,
    }),
    fetchPromotionNotifications: build.query<
      IRequestData<IBookingNotification[]>,
      ApiConnection & {
        params: TableParamsType
      }
    >({
      query: ({ bookingId, siteId, params }) => ({
        url: withPaginationParams(`/promotion/${bookingId}/notifications`, params),
        headers: { 'Database-Connection': siteId },
      }),
    }),

    fetchPromotionsDocuments: build.query<IInfoDocument[], ApiConnection>({
      query: ({ siteId, bookingId }) => ({
        url: `/promotion/${bookingId}/docs`,
        headers: { 'Database-Connection': siteId },
      }),
    }),

    fetchPromotionRecurring: build.query<IBooking[], ApiConnection>({
      query: ({ siteId, bookingId }) => ({
        url: `/promotion/${bookingId}/others`,
        headers: { 'Database-Connection': siteId },
      }),
    }),
    fetchBookingsByDateRange: build.query<
      PromotionBookingsType,
      ApiConnection & {
        startDate: string
        endDate: string
      }
    >({
      query: ({ siteId, bookingId, startDate, endDate }) => ({
        url: `/promotion/${bookingId}/bookings/${startDate}/${endDate}`,
        headers: { 'Database-Connection': siteId },
      }),
      providesTags: ['Bookings'],
    }),

    getDayRoomsAvailability: build.query<
      PromotionRoomAvailability[],
      ApiConnection & {
        date: string
      }
    >({
      query: ({ siteId, bookingId, date }) => ({
        url: `/promotion/${bookingId}/rooms-availability/${date}`,
        headers: { 'Database-Connection': siteId },
      }),
      providesTags: ['RoomAvailability'],
    }),

    getPromotionCalendar: build.query<
      PromotionMonth,
      ApiConnection & {
        date: string
      }
    >({
      query: ({ siteId, bookingId, date }) => ({
        url: `/promotion/${bookingId}/bookings-calendar/${date}`,
        headers: { 'Database-Connection': siteId },
      }),
      transformResponse: (response: { days: PromotionMonth }) => response.days,
    }),
    createPromotionRoomsBooking: build.mutation<
      PromotionBookingType,
      ApiConnection & {
        date: string
        data: {
          id: number
          slots: number
        }[]
      }
    >({
      query: ({ siteId, bookingId, date, data }) => ({
        data,
        url: `/promotion/${bookingId}/booking/${date}`,
        method: HTTP_METHODS.POST,
        headers: { 'Database-Connection': siteId },
      }),
      invalidatesTags: ['RoomAvailability', 'Bookings'],
    }),
    deleteSlotBooking: build.mutation<
      PromotionRoomAvailability[],
      ApiConnection & {
        ids: number[]
      }
    >({
      query: ({ siteId, bookingId, ids }) => ({
        url: `/promotion/${bookingId}/booking`,
        method: HTTP_METHODS.DELETE,
        headers: { 'Database-Connection': siteId },
        data: { ids },
      }),
      invalidatesTags: ['RoomAvailability', 'Bookings'],
    }),
    getPromotionSignContracts: build.query<PromotionSignContracts, ApiConnection>({
      query: ({ siteId, bookingId }) => ({
        url: `/promotion/${bookingId}/sign-contracts`,
        headers: { 'Database-Connection': siteId },
      }),
    }),
    getPromotionContractTemplate: build.query<any, ApiConnection>({
      query: ({ siteId, bookingId }) => ({
        url: `/promotion/${bookingId}/contract-template`,
        headers: { 'Database-Connection': siteId },
      }),
    }),

    checkoutPromotion: build.mutation<
      PromotionBookingType,
      ApiConnection &
        CheckoutDTO & {
          signature: string
        }
    >({
      query: ({ siteId, bookingId, ...data }) => ({
        data,
        url: `/promotion/${bookingId}/checkout`,
        method: HTTP_METHODS.POST,
        headers: { 'Database-Connection': siteId },
        invalidatesTags: ['RoomAvailability', 'Bookings', 'Promotions'],
      }),
    }),
  }),
})

export const {
  useLazyFetchPromotionNotificationsQuery,
  useFetchPromotionsDocumentsQuery,
  useLazyFetchPromotionsDocumentsQuery,
  useFetchPromotionsInfoQuery,
  useLazyFetchPromotionsInfoQuery,
  useFetchPromotionRecurringQuery,
  useLazyFetchPromotionRecurringQuery,
  useLazyFetchBookingsByDateRangeQuery,
  useLazyGetDayRoomsAvailabilityQuery,
  useCreatePromotionRoomsBookingMutation,
  useDeleteSlotBookingMutation,
  useGetPromotionCalendarQuery,
  useGetPromotionSignContractsQuery,
  useGetPromotionContractTemplateQuery,
  useLazyGetPromotionContractTemplateQuery,
  useCheckoutPromotionMutation,
} = promotionsApi
