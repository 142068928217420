import { useAuth } from 'app/providers'
import { useAppSelector } from 'common/hooks/redux'
import { type IRoomSelectedDay } from 'features/Booking/interfaces/ILeaseInfoRoom'
import { useMemo } from 'react'

export const useCartHasChanges = () => {
  const { user, isDoctor, hasCompletedDocuments } = useAuth()
  const { newLeaseInfo: newRoom } = useAppSelector((state) => state.leaseReducer)

  const isUnconfirmedDoctor = useMemo(
    () => isDoctor && !hasCompletedDocuments,
    [isDoctor, hasCompletedDocuments],
  )

  const cantSaveLease = useMemo(() => !user || isUnconfirmedDoctor, [user, isUnconfirmedDoctor])

  const { selectedDay, selectedDays = [] } = newRoom

  const hasNotChanges = useMemo(() => {
    try {
      if (selectedDay?.selectedSlot) return false

      const findSelectedDay = selectedDays?.find(
        (day: IRoomSelectedDay) => day?.date === selectedDay?.date,
      )
      const selectedDaySlots = selectedDay?.selectedSlots?.map((slot) => slot.id)

      if (!findSelectedDay && !selectedDaySlots?.length) return true

      const cartSlots = findSelectedDay?.selectedSlots?.map((slot) => slot.id)
      const hasSameSlots =
        cartSlots?.every((slot) => selectedDaySlots?.includes(slot)) &&
        selectedDaySlots?.length === cartSlots?.length
      return hasSameSlots
    } catch (error) {
      return false
    }
  }, [selectedDay, selectedDays])

  return !hasNotChanges && !cantSaveLease
}
