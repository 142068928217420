import type { IDiscount, IRoomTimeSlot } from 'features/Booking/interfaces/ILeaseInfoRoom'

export const groupSlots = <T extends { group_time: string }>(slots: T[] = []) => {
  return slots.reduce((acc: { [key: string]: T[] }, slot) => {
    const group = slot.group_time
    if (!acc[group]) acc[group] = []
    acc[group].push(slot)
    return acc
  }, {})
}

const defaultResult = { totalDiscount: 0, applicableDiscount: null }

export type CalculatedDiscount = {
  totalDiscount: number
  applicableDiscount: IDiscount | null
}

export const calculateTotalDiscount = (
  selectedSlotIds: number[],
  slots: IRoomTimeSlot[] = [],
  discounts: IDiscount[] = [],
): CalculatedDiscount => {
  if (!selectedSlotIds.length || !slots.length || !discounts.length) return defaultResult

  const sequences: number[][] = []
  let currentSequence: number[] = [selectedSlotIds[0]]

  for (let i = 1; i < selectedSlotIds.length; i++) {
    if (selectedSlotIds[i] === selectedSlotIds[i - 1] + 1) {
      currentSequence.push(selectedSlotIds[i])
    } else {
      if (currentSequence.length > 0) {
        sequences.push([...currentSequence])
      }
      currentSequence = [selectedSlotIds[i]]
    }
  }
  if (currentSequence.length > 0) {
    sequences.push(currentSequence)
  }

  const sortedDiscounts = Array.from(discounts).sort((a, b) => b.slots - a.slots)
  let totalDiscount = 0
  let maxApplicableDiscount: IDiscount | null = null

  sequences.forEach((sequence) => {
    const applicableDiscount = sortedDiscounts.find((discount) => sequence.length >= discount.slots)

    if (applicableDiscount) {
      const sequencePrice = sequence.reduce((sum, id) => {
        const slot = slots.find((slot) => slot.id === id)
        return sum + (slot?.price || 0)
      }, 0)

      totalDiscount += (sequencePrice * applicableDiscount.value) / 100

      if (!maxApplicableDiscount || applicableDiscount.value > maxApplicableDiscount.value) {
        maxApplicableDiscount = applicableDiscount
      }
    }
  })

  return {
    totalDiscount,
    applicableDiscount: maxApplicableDiscount,
  }
}
