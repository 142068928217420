import { useEffect, useState } from 'react'

import { Tooltip } from 'antd'
import classNames from 'classnames'
import { format, parse } from 'date-fns'

import warningIcon from 'assets/icons/warning.svg'
import cancelIcon from 'features/Home/assets/cancel.svg'
import checkIcon from 'features/Home/assets/check.svg'
import checkoutIcon from 'features/Home/assets/checkout.svg'
import dragIcon from 'features/Home/assets/drag.svg'
import resizeIcon from 'features/Home/assets/resize.svg'
import deleteIcon from 'features/Home/assets/trash.svg'
import userIcon from 'features/Home/assets/user.svg'

import { Show } from 'common/components/Show/Show'
import { ALT_CONSTANTS } from 'common/constants/altConstants'
import { TIME_FORMAT } from 'common/constants/timeFormat'
import { useAppSelector } from 'common/hooks/redux'
import { DateService } from 'common/services/dateService'

import {
  ACTION_MESSAGE,
  ACTION_TYPES,
  ALLOW_RESCHEDULE_CURRENT_DAY_PAST_EVENTS,
  APPOINTMENT_STATUS_ALLOWED_ACTIONS,
  INFO_CONSTANTS,
  PATIENT_STATUS_NAMES,
  PATIENT_STATUSES,
  SMS_STATUSES,
} from 'features/Home/constants/infoConstants'
import { ROOM_TYPE_IDS } from 'features/Home/constants/room.constants'
import { InfoService } from 'features/Home/services/infoService'

// Had to disable next line, part of the classes are used in calendar file
import styles from 'features/Home/Book/Booking/Schedule/PatientSchedule/Calendar/calendar.module.scss'
import { SMSIcon } from './SMSIcon/SMSIcon'

const tooltip = (prop: any, disabledEverything: boolean) => {
  const { event } = prop
  const { status } = event

  const getActionIcon = (action: ACTION_TYPES) => {
    switch (action) {
      case ACTION_TYPES.RESCHEDULE:
        return dragIcon

      case ACTION_TYPES.RESIZE_SCHEDULE:
        return resizeIcon

      case ACTION_TYPES.DELETE:
        return deleteIcon

      case ACTION_TYPES.CANCEL:
        return cancelIcon

      case ACTION_TYPES.EDIT_TEMPERATURE:
        return checkIcon

      case ACTION_TYPES.EDIT_CONSULTATION_STAFF:
        return userIcon

      case ACTION_TYPES.CHECK_OUT:
        return checkoutIcon

      default:
        return ''
    }
  }

  return (
    <div className={styles.tooltip}>
      <Show
        when={
          (disabledEverything && !ALLOW_RESCHEDULE_CURRENT_DAY_PAST_EVENTS.includes(status.code)) ||
          APPOINTMENT_STATUS_ALLOWED_ACTIONS[status.code as PATIENT_STATUSES].length === 0
        }>
        <span>You can't do any action for this appointment.</span>
      </Show>
      <Show
        when={
          !(
            disabledEverything && !ALLOW_RESCHEDULE_CURRENT_DAY_PAST_EVENTS.includes(status.code)
          ) && APPOINTMENT_STATUS_ALLOWED_ACTIONS[status.code as PATIENT_STATUSES].length > 0
        }>
        <p className={styles.tooltipTitle}>
          For this appointment you can do the following actions:
        </p>

        {APPOINTMENT_STATUS_ALLOWED_ACTIONS[status.code as PATIENT_STATUSES].map((action) => (
          <div className={styles.tooltipActionItem} key={action}>
            <img
              className={styles.tooltipIcon}
              src={getActionIcon(action)}
              alt={ALT_CONSTANTS.ACTION_ICON}
            />
            <span>{ACTION_MESSAGE[action]}</span>
          </div>
        ))}
      </Show>
    </div>
  )
}

const calendarEvent = (prop: any, timeZone: string) => {
  // REDUX state is used since calendarEvent do not catch any changes of isDragging state (hook) from Calendar
  // (prop, timeZone, isDragging) is not working
  const { isDragging } = useAppSelector((state) => state.bookReducer)

  const [tooltipVisible, setTooltipVisible] = useState(false)
  const [previousLocation, setPreviousLocation] = useState<string | null>(null)

  const { event } = prop

  const appointment = JSON.parse(JSON.stringify(event))

  const { start, end } = event

  const disableEverything =
    DateService.isPast(event.start, timeZone) &&
    event.status.code !== PATIENT_STATUSES.CHECKED_IN_ON_SITE

  useEffect(() => {
    const eventContainer = document.getElementById(`${event.id}-event-container`)
    let touched = false
    const displayTooltip = () => {
      if (!touched) {
        setTooltipVisible(true)
      }
    }

    const hideTooltip = () => {
      setTooltipVisible(false)
    }

    const handleTouched = () => {
      touched = true
      hideTooltip()
    }

    if (eventContainer) {
      eventContainer.addEventListener('pointerdown', handleTouched)
      eventContainer.addEventListener('mouseenter', displayTooltip)
      eventContainer.addEventListener('mouseleave', hideTooltip)
      window.addEventListener('mousedown', hideTooltip)
    }
    return () => {
      if (eventContainer) {
        eventContainer.removeEventListener('pointerdown', handleTouched)
        eventContainer.removeEventListener('mouseenter', displayTooltip)
        eventContainer.removeEventListener('mouseleave', hideTooltip)
        window.removeEventListener('mousedown', hideTooltip)
      }
    }
  }, [appointment])

  useEffect(() => {
    if (prop.event.current_location) {
      setPreviousLocation(prop.event.current_location)
    }
  }, [prop.event.current_location])

  const handleScroll = () => {
    const eventContainer = document.getElementById(`${event.id}-event-container`)
    const rbcHeader = document.querySelector('.rbc-header')

    if (eventContainer && rbcHeader) {
      const eventRect = eventContainer.getBoundingClientRect()
      const headerRect = rbcHeader.getBoundingClientRect()

      const distanceFromTopEvent = eventRect.top + window.scrollY
      const distanceFromTopHeader = headerRect.top + window.scrollY

      const difference = distanceFromTopEvent - distanceFromTopHeader

      if (difference < 200) {
        setTooltipVisible(false)
      }
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  useEffect(() => {
    const element = document.getElementsByClassName('rbc-time-content')
    if (element.length > 0) {
      element[0].addEventListener('scroll', handleScroll)
    }

    return () => {
      if (element.length > 0) {
        element[0].removeEventListener('scroll', handleScroll)
      }
    }
  }, [])

  const eventTime = (() => {
    if (!event.start || !event.end) return ''
    const start = format(event.start, TIME_FORMAT.TIME_GUTTER_PICKER_HH_SS)
    const end = format(event.end, TIME_FORMAT.TIME_GUTTER_PICKER_HH_SS)
    return `${start}—${end}`
  })()

  const getAppointmentTime = () => {
    if (event.room_type_id !== ROOM_TYPE_IDS.WAITING_ROOM) return
    const { appointment_start_time, appointment_end_time, original_room } = event
    if (!appointment_start_time || !appointment_end_time) return ''
    const startDate = parse(appointment_start_time, 'HH:mm:ss', new Date())
    const endDate = parse(appointment_end_time, 'HH:mm:ss', new Date())

    const start = format(startDate, TIME_FORMAT.TIME_GUTTER_PICKER_HH_SS)
    const end = format(endDate, TIME_FORMAT.TIME_GUTTER_PICKER_HH_SS)
    return `Original appointment: (${start} - ${end}, ${original_room})`
  }

  const differenceInMinutes = (end.getTime() - start.getTime()) / (1000 * 60)
  const isShortEvent = differenceInMinutes <= 6

  const appointmentOriginalTime = getAppointmentTime()

  return (
    <>
      {event.status.code === PATIENT_STATUSES.DISABLED ? null : (
        <Tooltip
          title={() => tooltip(prop, disableEverything)}
          open={!isDragging && tooltipVisible}>
          <div
            className={classNames(styles.parentEventContainer, {
              [styles.parentShortEventContainer]: isShortEvent,
            })}
            id={`${event.id}-event-container`}>
            <Show
              when={
                prop.event.sms_notification !== SMS_STATUSES.UNCONFIRMED &&
                prop.event.status.code !== PATIENT_STATUSES.CHECK_IN_REJECTED
              }>
              <div className={styles.parentEventSms}>
                <SMSIcon
                  status={prop.event.sms_notification}
                  color={InfoService.getScheduleColor(prop.event.status.code).color}
                />
              </div>
            </Show>
            <Show when={prop.event.status.code === PATIENT_STATUSES.CHECK_IN_REJECTED}>
              <div className={styles.parentEventSms}>
                <img src={warningIcon} alt={ALT_CONSTANTS.STATUS_ICON} />
              </div>
            </Show>

            <p
              className={classNames(styles.parentEventTitle, {
                [styles.parentShortEventTitle]: isShortEvent,
              })}>
              {prop.event.title}
            </p>
            <p className='rbc-event-label m-0'>{eventTime}</p>
            <Show when={appointmentOriginalTime}>
              <p style={{ lineHeight: '150%' }} className='rbc-event-label'>
                {appointmentOriginalTime}
              </p>
            </Show>

            <Show when={prop.event.status.code !== PATIENT_STATUSES.CHECKED_IN_ON_SITE}>
              <p
                className={classNames(styles.parentEventStatus, {
                  [styles.parentShortEventStatus]: isShortEvent,
                })}>
                {PATIENT_STATUS_NAMES[prop.event.status.code as keyof typeof PATIENT_STATUS_NAMES]}{' '}
                {prop.event.error_sms_message ? (
                  <span className={styles.parentErrorSmsMessage}>
                    ({prop.event.error_sms_message})
                  </span>
                ) : null}
              </p>
            </Show>
            <Show when={prop.event.status.code === PATIENT_STATUSES.CHECKED_IN_ON_SITE}>
              <p
                className={classNames(styles.parentEventStatus, {
                  [styles.parentShortEventStatus]: isShortEvent,
                })}>
                <span>{INFO_CONSTANTS.NOW_IN} </span>
                {prop.event.current_location
                  ? prop.event.current_location
                  : previousLocation
                  ? previousLocation
                  : 'N/A'}
              </p>
            </Show>
            <Show
              when={
                prop.event.status.code === PATIENT_STATUSES.CHECK_IN_REJECTED ||
                prop.event.status.code === PATIENT_STATUSES.CHECKED_OUT
              }>
              <p className={styles.parentEventStatus}>
                <span>{INFO_CONSTANTS.REASON}</span>
                {prop.event.notes || 'N/A'}
                <Show
                  when={
                    prop.event.temperature &&
                    prop.event.status.code !== PATIENT_STATUSES.CHECKED_OUT
                  }>
                  <span
                    className={
                      styles.parentEventTemperature
                    }>{` ${prop.event.temperature}°F`}</span>
                </Show>
              </p>
            </Show>
          </div>
        </Tooltip>
      )}
    </>
  )
}

export default calendarEvent
