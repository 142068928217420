import './roomAvailability.scss'

import { type FC, memo, useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { Spin } from 'antd'
import dayjs from 'dayjs'
import { useFormik } from 'formik'

import { Button } from 'common/components/Button/Button'
import { Modal } from 'common/components/Modal'
import Show from 'common/components/Show2/Show'
import { useAppDispatch } from 'common/hooks/redux'

import {
  useCreatePromotionRoomsBookingMutation,
  useLazyGetDayRoomsAvailabilityQuery,
} from 'features/Home/Promotion/state/promotionsApi'
import { updatePromotionLatestCrud } from 'features/Home/Promotion/state/promotionsSlice'
import type { RoomAvailabilityProps } from './roomAvailability.types'
import { RoomLoadingSkeleton } from './RoomLoadingSkeleton'
import { RoomBookingSlots } from './RoomBookingSlots'

export const RoomAvailability: FC<RoomAvailabilityProps> = ({
  date,
  conventionRooms,
  skeletonRooms = 1,
  open,
  handleCancel,
}) => {
  const dispatch = useAppDispatch()

  const { bookingId, siteId } = useParams()

  const [fetchRoomAvailability, { data, isFetching }] = useLazyGetDayRoomsAvailabilityQuery()

  const [createBooking] = useCreatePromotionRoomsBookingMutation()

  const { values, isSubmitting, setFieldValue, handleSubmit, resetForm } = useFormik({
    initialValues: {
      slots: {},
    },
    onSubmit,
    enableReinitialize: true,
  })

  function onCancel() {
    resetForm()
    handleCancel()
  }

  async function onSubmit() {
    try {
      const data = Object.keys(values.slots).map((key) => {
        return {
          id: Number(key),
          slots: values.slots[key],
        }
      })
      const response = await createBooking({
        bookingId,
        siteId,
        date,
        data,
      })

      dispatch(updatePromotionLatestCrud(response.data))
      onCancel()
    } catch (error) {}
  }

  const handleSlotToggle = (roomId: number, slotId: number) => {
    const roomsSlots = values.slots[roomId] ?? []
    const prevSelectedSlotIds = new Set(roomsSlots)
    if (prevSelectedSlotIds.has(slotId)) {
      prevSelectedSlotIds.delete(slotId)
    } else {
      prevSelectedSlotIds.add(slotId)
    }
    setFieldValue(`slots.${roomId}`, Array.from(prevSelectedSlotIds))
  }

  const handleGroupToggle = (roomId: number, groupSlotIds: number[]) => {
    const roomsSlots = values.slots[roomId] ?? []
    const prevSelectedSlotIds = new Set(roomsSlots)
    const allSelected = groupSlotIds.every((id) => prevSelectedSlotIds.has(id))
    if (allSelected) {
      groupSlotIds.forEach((id) => prevSelectedSlotIds.delete(id))
    } else {
      groupSlotIds.forEach((id) => prevSelectedSlotIds.add(id))
    }
    setFieldValue(`slots.${roomId}`, Array.from(prevSelectedSlotIds))
  }

  const getConventionRoom = (roomId: number) => {
    return conventionRooms.find((room) => room.id === roomId)!
  }

  const hasSelectedSlot = Object.keys(values.slots).some((key) => values.slots[key]?.length)

  useEffect(() => {
    if (open && date) {
      fetchRoomAvailability({
        bookingId,
        siteId,
        date,
      })
    }
  }, [open])

  const getIsWeekend = () => {
    const selectedDate = dayjs(date).startOf('day')
    const dayOfWeek = selectedDate.day()
    return dayOfWeek === 0 || dayOfWeek === 6
  }

  const isWeekend = getIsWeekend()

  const checkHasReachedMaxSlots = (roomId: number, candidate: number) => {
    const conventionRoom = getConventionRoom(roomId)
    if (!conventionRoom) return false
    const slots = isWeekend ? conventionRoom.weekend_slots : conventionRoom.weekday_slots
    return slots - (values.slots[roomId]?.length || 0) < candidate
  }

  const getDisabledMessage = (roomId: number, isInactive: boolean): string | false => {
    const conventionRoom = getConventionRoom(roomId)
    if (!conventionRoom) return false
    const slots = isWeekend ? conventionRoom.weekend_slots : conventionRoom.weekday_slots
    // if (slots - (values.slots[roomId]?.length || 0) <= 0)
    //   return "You've reached maximum slots for this week!"
    return false
  }

  const getDefaultSelectedSlots = (roomId: number) => {
    return values.slots[roomId] ?? []
  }

  return (
    <Modal
      wrapClassName='promotion-room-availability-wrapper'
      destroyOnClose
      onCancel={onCancel}
      open={open}
      title='I would like to book'>
      <Spin spinning={isSubmitting}>
        <div className='promotion-room-availability'>
          <Show>
            <Show.When isVisible={isFetching}>
              <RoomLoadingSkeleton skeletonRooms={skeletonRooms} />
            </Show.When>
            <Show.Else>
              {data?.map((room) => {
                return (
                  <RoomBookingSlots
                    key={room.id}
                    room={room}
                    handleSlotToggle={handleSlotToggle}
                    handleGroupToggle={handleGroupToggle}
                    selectedSlotIds={getDefaultSelectedSlots(room.id)}
                    checkHasReachedMaxSlots={(cand) => checkHasReachedMaxSlots(room.id, cand)}
                  />
                )
              })}
              <div className='footer-container'>
                <Button
                  disabled={isSubmitting || !hasSelectedSlot}
                  htmlType='submit'
                  onClick={() => handleSubmit()}>
                  Add
                </Button>
              </div>
            </Show.Else>
          </Show>
        </div>
      </Spin>
    </Modal>
  )
}
