import type { ReactElement } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'

import FallbackComponent from 'common/components/FallbackComponent/FallbackComponent'

import { PATH_SEGMENT } from './pathSegments'

export type RoutesConfigType = Record<string, ReactElement>

export const generateRoute = (
  path: string,
  element: ReactElement,
  routeProps = {},
  hasErrorBoundary = true,
) => {
  const resolvedElement = (
    <FallbackComponent hasErrorBoundary={hasErrorBoundary}>{element}</FallbackComponent>
  )

  return <Route path={path} key={path} element={resolvedElement} {...routeProps} />
}

export const generateRouter = (reutesConfig: RoutesConfigType, includeErrorRoute = true) => {
  const routesJSX = Object.entries(reutesConfig).map(([path, element]: [string, ReactElement]) =>
    generateRoute(path, element),
  )

  includeErrorRoute &&
    routesJSX.push(<Route path='*' element={<Navigate to={PathUtils.getError('404')} replace />} />)

  return (
    <div className='container'>
      <Routes>{routesJSX}</Routes>
    </div>
  )
}

export const PathUtils = {
  _errorRoute: <></>,
  get errorRoute() {
    this._errorRoute = this._errorRoute || this.getErrorRoute()
    return this._errorRoute
  },

  getHome(siteId: string): string {
    return `/${siteId}/${PATH_SEGMENT.HOME}`
  },

  getProfile(siteId: string): string {
    return `/${siteId}/${PATH_SEGMENT.PROFILE}`
  },

  getAuth(): string {
    return [PATH_SEGMENT.ROOT, PATH_SEGMENT.AUTH].join('')
  },

  getLogin(): string {
    return [this.getAuth(), PATH_SEGMENT.LOGIN].join('/')
  },

  getResetPassword(): string {
    return [this.getAuth(), PATH_SEGMENT.RESET_PASSWORD].join('/')
  },

  getValidateEmail(email: string): string {
    return [this.getAuth(), PATH_SEGMENT.VALIDATE_EMAIL, email].join('/')
  },

  getRegister(): string {
    return [this.getAuth(), PATH_SEGMENT.REGISTER].join('/')
  },

  getTermsAndConditions(): string {
    return [this.getAuth(), PATH_SEGMENT.TERMS_AND_CONDITIONS].join('/')
  },

  getValidateIdentity(): string {
    return [PATH_SEGMENT.ROOT, PATH_SEGMENT.VALIDATE_IDENTITY].join('')
  },

  getValidationProcess(): string {
    return [PATH_SEGMENT.ROOT, PATH_SEGMENT.VALIDATION_PROCESSING].join('')
  },

  getUserTermsAndConditions(): string {
    return [PATH_SEGMENT.PROVIDER, PATH_SEGMENT.TERMS_AND_CONDITIONS].join('/')
  },

  getAdmin(): string {
    return [PATH_SEGMENT.ROOT, PATH_SEGMENT.ADMIN].join('')
  },

  getGuest(): string {
    return [PATH_SEGMENT.ROOT, PATH_SEGMENT.GUEST].join('')
  },

  getBookingDetails(siteId: string, bookingId: string): string {
    return [this.getHome(siteId), PATH_SEGMENT.BOOKING, bookingId, PATH_SEGMENT.DETAILS].join('/')
  },

  getPatientSchedule(siteId: string, bookingId: string): string {
    return [this.getHome(siteId), PATH_SEGMENT.BOOKING, bookingId, PATH_SEGMENT.SCHEDULE].join('/')
  },

  getBook(siteId: string): string {
    return [this.getHome(siteId), PATH_SEGMENT.BOOK, siteId].join('/')
  },

  getGuestBook(siteId: string): string {
    return [this.getGuest(siteId), PATH_SEGMENT.HOME, PATH_SEGMENT.BOOK, siteId].join('/')
  },

  getPromotionDetails(siteId: string, bookingId: string): string {
    return [
      this.getHome(siteId),
      PATH_SEGMENT.PROMOTION,
      bookingId,
      PATH_SEGMENT.DETAILS,
      PATH_SEGMENT.ROOMS,
    ].join('/')
  },

  getPromotion(siteId: string, bookingId: string): string {
    return [this.getHome(siteId), PATH_SEGMENT.PROMOTION, bookingId].join('/')
  },

  getPromotionSchedule(siteId: string, bookingId: string): string {
    return [this.getHome(siteId), PATH_SEGMENT.PROMOTION, bookingId, PATH_SEGMENT.SCHEDULE].join(
      '/',
    )
  },

  getError(code: string): string {
    return `/${[PATH_SEGMENT.ERROR, code].join('/')}`
  },
}
