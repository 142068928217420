import dayjs from 'dayjs'

export const INFO_STAFF_TABLE_COLUMNS = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    sorter: true,
  },
  {
    title: 'Phone',
    dataIndex: 'phone_number',
    key: 'phone_number',
    sorter: true,
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
    sorter: true,
  },
  {
    title: 'Qualification',
    dataIndex: 'qualification',
    key: 'qualification',
    sorter: true,
  },
  {
    title: 'Access Code',
    dataIndex: 'access_code',
    key: 'access_code',

    sorter: true,
  },
  {
    title: 'Last Active',
    dataIndex: 'last_active',
    key: 'last_active',
    sorter: true,
    render: (last_active: string) =>
      last_active ? dayjs(last_active).format("ddd, MMM DD 'YY") : 'N/A',
  },
  {
    title: 'Last Notify',
    dataIndex: 'last_notify',
    render: (last_notify: string) =>
      last_notify ? dayjs(last_notify).format("ddd, MMM DD 'YY") : 'N/A',
    sorter: true,
  },
]
